import http from '@chinamo/http';
import token from './token';
import { ElMessage } from 'element-plus';

/**
 * 处理斜杠，添加开始斜杠和去除尾斜杠
 * @param {string} url URL
 * @returns 处理后的URL
 */
function handleSlash(url = '') {
  if (url.length && url !== '/' && !url.startsWith('/')) {
    url = '/' + url;
  }
  return url.replace(/(.*)\//, '$1');
}

//全局接口路径前缀
http.defaults.baseURL = handleSlash(process.env.BASE_URL);

http.interceptors.request.use(config => {
  return config.withoutToken || config.method === 'get'
    ? config
    : Promise.resolve(token.refresh()).then(token => {
        config.headers['X-Xsrf-Token'] = token;
        return config;
      });
});

//响应拦截器
http.interceptors.response.use(
  ({ data }) => {
    //如果存在成功信息，则提示信息
    if (data?.message) {
      ElMessage.success(data.message);
    }
    return data;
  },
  error => {
    const { response, config, code } = error;

    //如果存在后台信息，则获取后台信息
    let message = response?.data?.message;

    //如果存在国际化，则进行取国际化信息
    if (http.$t) {
      message = message || http.$t(`http.error.${code}`);
    }

    //如果不存在信息，则取异常信息
    if (!message) {
      message = error.message;
    }

    //如未标记静默处理，则提示信息
    if (!config?.silent) {
      ElMessage.error(message);
    }

    if (401 === response?.status) {
      http.handleUnauthorized();
    }

    //将新信息赋值回异常，自定义捕获有需要时使用
    error.message = message;
    return Promise.reject(error);
  }
);

export default http;
