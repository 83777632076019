import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted, ref, watch, computed, toRef } from 'vue';
import { injectScript as injectMapScript } from '@/hooks/use-baidu-map';
import pointSmallPng from '@/assets/image/point-small.png';
import pointBigPng from '@/assets/image/point-big.png';
import trainPng from '@/assets/image/train.png';
export default {
  __name: 'track-map',
  props: {
    coordinate: Boolean,
    tracks: Array,
    lushu: Boolean
  },
  emits: ['coordinate-picked'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const mapContainerRef = ref();
    onMounted(() => {
      injectMapScript(props.lushu ? ['LuShu'] : []).then(() => {
        const smallIcon = new BMap.Icon(pointSmallPng, new BMap.Size(16, 16));
        const bigIcon = new BMap.Icon(pointBigPng, new BMap.Size(25, 25));
        const map = new BMap.Map(mapContainerRef.value);
        const geoc = new BMap.Geocoder();
        map.centerAndZoom(new BMap.Point(103.1589334594464, 30.239576227663534), 5);
        map.enableScrollWheelZoom(true);

        // 渲染轨迹
        function renderTracks(tracks) {
          map.clearOverlays(); // 清除地图已加载的覆盖物
          if (!tracks || !tracks.length) {
            return;
          }
          const points = [];
          let minLng,
            maxLng,
            minLat,
            maxLat = 0;
          for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            const lng = track.longitude;
            const lat = track.latitude;
            if (!lng || !lat) {
              continue;
            }
            const point = new BMap.Point(lng, lat);
            points.push(point);

            // 设置坐标点
            const marker = new BMap.Marker(point, {
              icon: smallIcon,
              title: track.location
            });
            marker.addEventListener('click', function (event) {
              event.target.openInfoWindow(new BMap.InfoWindow(`<div>${track.location}</div><div>${track.collectTime}</div>`, {
                width: 0,
                height: 0
              })); //开启信息窗口
            });
            marker.addEventListener('mouseover', function (event) {
              event.target.setIcon(bigIcon);
            });
            marker.addEventListener('mouseout', function (event) {
              event.target.setIcon(smallIcon);
            });
            map.addOverlay(marker);

            // 计算地图中心点
            if (i == 0) {
              minLng = lng;
              minLat = lat;
            }
            minLng = minLng < lng ? minLng : lng;
            maxLng = maxLng > lng ? maxLng : lng;
            minLat = minLat < lat ? minLat : lat;
            maxLat = maxLat > lat ? maxLat : lat;
          }
          if (minLng != 0 && minLat != 0) {
            const $lng = (parseFloat(minLng) + parseFloat(maxLng)) / 2;
            const $lat = (parseFloat(minLat) + parseFloat(maxLat)) / 2;
            map.centerAndZoom(new BMap.Point($lng, $lat), $lng / $lat);
          }

          // 连线
          const polyline = new BMap.Polyline(points, {
            strokeColor: '#000000',
            strokeWeight: 2,
            strokeOpacity: 0.8,
            strokeStyle: 'dashed'
          });
          map.addOverlay(polyline);

          // 火车路书
          if (props.lushu && points.length > 1) {
            const lushu = new BMapLib.LuShu(map, polyline.getPath(), {
              //geodesic: true,
              autoCenter: true,
              icon: new BMap.Icon(trainPng, new BMap.Size(94, 32), {
                anchor: new BMap.Size(47, 16)
              }),
              speed: 1000000,
              enableRotation: true,
              landmarkPois: []
            });
            setTimeout(() => lushu.start(), 1000);
          }
        }

        // 点击地图获取位置信息
        let lastMarker;
        map.addEventListener('click', function (e) {
          if (props.coordinate) {
            const point = e.point;
            const marker = new BMap.Marker(new BMap.Point(point.lng, point.lat));
            map.addOverlay(marker);
            lastMarker && map.removeOverlay(lastMarker);
            lastMarker = marker;
            geoc.getLocation(point, function (rs) {
              emit('coordinate-picked', {
                point,
                location: rs.addressComponents
              });
            });
          }
        });
        watch(toRef(props, 'tracks'), tracks => tracks && renderTracks(tracks));
        watch(toRef(props, 'coordinate'), coordinate => !coordinate && lastMarker && map.removeOverlay(lastMarker));
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "mapContainerRef",
        ref: mapContainerRef
      }, null, 512);
    };
  }
};