import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "print-view-area-page"
};
const _hoisted_2 = {
  class: "print-view-area-page-header"
};
const _hoisted_3 = {
  class: "print-view-area-page-header-printer"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "print-view-area-page-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, page => {
    return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("label", _hoisted_3, [_ctx.showPrinter ? (_openBlock(), _createElementBlock("span", _hoisted_4, " 打印人:" + _toDisplayString(_ctx.printer) + "  打印日期:" + _toDisplayString(_ctx.$dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')), 1)) : _createCommentVNode("", true)]), _createElementVNode("label", {
      class: "print-view-area-page-header-title",
      style: _normalizeStyle(`${_ctx.showPrinter ? '' : 'line-height:20mm'}`)
    }, _toDisplayString(_ctx.title), 5)]), _createElementVNode("div", {
      class: "print-view-area-page-data",
      style: _normalizeStyle(`height:${_ctx.portrait ? 250.5 : 163.5}mm`)
    }, [_renderSlot(_ctx.$slots, "page", {
      currentPage: page,
      pageIndex: page - 1
    })], 4), _createElementVNode("div", _hoisted_5, [_ctx.showFooter ? (_openBlock(), _createBlock(_component_el_divider, {
      key: 0
    })) : _createCommentVNode("", true), _ctx.showFooter ? (_openBlock(), _createBlock(_component_el_descriptions, {
      key: 1,
      style: {
        "margin-top": "5mm"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
        width: "45%",
        "label-align": "left",
        label: "蜀道铁路运营集团·长江班服务平台"
      }), _createVNode(_component_el_descriptions_item, {
        width: "10%",
        align: "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(page) + "/" + _toDisplayString(_ctx.totalPages), 1)]),
        _: 2
      }, 1024), _createVNode(_component_el_descriptions_item, {
        width: "45%",
        align: "right"
      }, {
        default: _withCtx(() => [_createTextVNode("蜀道铁路运营集团")]),
        _: 1
      })]),
      _: 2
    }, 1024)) : _createCommentVNode("", true)])]);
  }), 256);
}