import shiroTrie from 'shiro-trie';

const shiro = shiroTrie.newTrie();

/**
 * 检查多个权限，满足任意一个即为通过
 * @param {array} permissions 权限
 * @returns 是否通过
 */
shiro.some = function (...permissions) {
  return permissions.some(permission => this.check(permission));
};

/**
 * 检查多个权限，满足所有即为通过
 * @param {array} permissions 权限
 * @returns 是否通过
 */
shiro.every = function (...permissions) {
  return permissions.every(permission => this.check(permission));
};

export default shiro;
