import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  name: 'EeContainerCategorySelect',
  props: {
    defaultData: {
      type: Array,
      default: []
    },
    initMethod: {
      type: Function
    }
  },
  setup(props) {
    const loading = ref(false);
    const containerCategorys = ref([]);
    const init = () => {
      if (props.defaultData.length > 0) {
        containerCategorys.value = props.defaultData;
      } else {
        loading.value = true;
        props.initMethod().then($containerCategorys => {
          containerCategorys.value = $containerCategorys || [];
        }).finally(() => loading.value = false);
      }
    };
    onMounted(init);
    return {
      loading,
      containerCategorys
    };
  }
});