import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: "输入班次号搜索班次",
    "remote-method": _ctx.handleRemoteMethod,
    loading: _ctx.loading,
    long: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trains, train => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: train.id,
        value: train.id,
        label: train.name
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(train.name), 1)]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(train.trainLine.name), 1)]),
            _: 2
          }, 1024), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$dayjs(train.planDepartDate).format('YYYY-MM-DD')), 1)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1032, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["remote-method", "loading"]);
}