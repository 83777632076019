import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, watch } from 'vue';
import { ElMessage as $msg } from 'element-plus';
export default defineComponent({
  name: 'EeExportExcelView',
  props: {
    columns: {
      type: Array,
      default: []
    },
    columnsLoader: {
      type: Function
    },
    pagination: {
      type: Boolean,
      default: true
    },
    loader: {
      type: Function,
      required: true
    },
    exportUrl: {
      type: String,
      required: true
    },
    filterDetailFormatter: {
      type: Object,
      default: {}
    }
  },
  emits: ['beforeExport', 'reset', 'filtersData'],
  setup(props, {
    emit,
    slots,
    expose
  }) {
    const more = ref(false);
    const pcolumns = props.columns || [];
    const pagination = props.pagination;
    const downloadHref = props.exportUrl;
    const formatter = props.filterDetailFormatter;
    const tableData = ref([]);
    const showColumns = ref([]);
    const tableRef = ref();
    const formRef = ref({});
    const selectedColumnCodes = ref([]);
    const filters = reactive({
      selectedColumnCodes: selectedColumnCodes.value
    });
    const columns = ref([]);
    //导出倒计时计时器,默认30秒
    const countDownTimer = reactive({
      countNum: '',
      countFlag: false,
      intervalBtn: null
    });
    props.columnsLoader && Promise.resolve(props.columnsLoader()).then($columns => {
      $columns.forEach(col => {
        col = Object.assign(col, pcolumns.filter(pcol => pcol.code === col.code)[0]);
      });
      columns.value = $columns || [];
      showColumns.value = columns.value.filter(col => col.isDisplay);
    });
    const changeShowColumns = () => {
      showColumns.value = columns.value.filter(col => selectedColumnCodes.value.includes(col.code));
      filters.selectedColumnCodes = selectedColumnCodes.value;
    };
    watch(selectedColumnCodes, changeShowColumns);
    watch(filters, (newVal, oldVal) => {
      emit('filtersData', newVal);
    });
    const countDown = () => {
      if (!countDownTimer.intervalBtn) {
        countDownTimer.countNum = 0;
        countDownTimer.countFlag = true;
        countDownTimer.intervalBtn = setInterval(() => {
          if (countDownTimer.countNum == 0) {
            countDownTimer.countNum = '';
            countDownTimer.countFlag = false;
            clearInterval(countDownTimer.intervalBtn);
            countDownTimer.intervalBtn = null;
          } else {
            countDownTimer.countNum--;
          }
        }, 1000);
      }
    };
    const load = async reset => {
      if (reset) {
        formRef.value.resetFields();
        for (let key in filters) {
          if (typeof filters[key] != 'undefined') {
            filters[key] = undefined;
          }
        }
        emit('reset', true);
      }
      if (pagination) {
        tableRef.value.pagination(reset);
      } else {
        tableData.value = (await props.loader({
          ...filters
        })) || [];
      }
    };
    onMounted(load);
    expose({
      filters,
      load
    });
    return {
      more,
      slots,
      formRef,
      pagination,
      tableRef,
      filters,
      tableData,
      columns,
      showColumns,
      selectedColumnCodes,
      downloadHref,
      countDownTimer,
      formatter,
      load,
      loader: pageable => pagination && props.loader({
        ...pageable,
        ...filters
      }),
      handleSelectedAll: () => {
        columns.value.forEach(col => {
          selectedColumnCodes.value.push(col.code);
        });
        changeShowColumns();
      },
      handleSelectedDefault: () => {
        selectedColumnCodes.value = [];
        columns.value.forEach(col => {
          if (col.isDisplay) {
            selectedColumnCodes.value.push(col.code);
          }
        });
        changeShowColumns();
      },
      objValue(obj, attr) {
        if (attr.includes('.')) {
          const attrs = attr.split('.');
          attrs.forEach(a => {
            if (!obj) {
              return '';
            }
            obj = obj[a];
          });
          return obj;
        } else {
          return obj[attr];
        }
      },
      handleBeforeDownload(download) {
        if (selectedColumnCodes.value.length < 1) {
          $msg({
            message: '不能导出空列！',
            type: 'warning'
          });
          download(false);
          return;
        }
        let total = 0;
        if (pagination) {
          total = tableRef.value.total;
        } else {
          total = tableRef.value.data.length;
        }
        if (total === 0) {
          $msg({
            message: '不能导出空数据！',
            type: 'warning'
          });
          download(false);
          return;
        }
        emit('beforeExport', download);
        countDown();
      }
    };
  }
});