import { defineComponent, ref, onMounted } from 'vue';
import print from 'vue3-print-nb';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'EePrint',
  directives: {
    print
  },
  props: {
    portrait: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    const printer = store.state.profile.name;
    const printId = 'print' + new Date().getTime();
    const printOption = {
      id: printId,
      popTitle: ''
    };
    return {
      printId,
      printer,
      printOption
    };
  }
});