import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  return _ctx.value ? (_openBlock(), _createBlock(_component_el_tag, {
    key: 0,
    type: _ctx.trueType
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.trueText), 1)]),
    _: 1
  }, 8, ["type"])) : (_openBlock(), _createBlock(_component_el_tag, {
    key: 1,
    type: _ctx.falseType
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.falseText), 1)]),
    _: 1
  }, 8, ["type"]));
}