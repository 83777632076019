import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { defineComponent, ref, watch, computed } from 'vue';
export default defineComponent({
  name: 'EeGoodsSelect',
  emits: ['selectedItems', 'update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      default: []
    },
    defaultData: {
      type: Array,
      default: []
    },
    remoteMethod: {
      type: Function
    }
  },
  setup(props, {
    emit
  }) {
    const loading = ref(false);
    const keyword = ref('');
    const inputRef = ref();
    const goodses = ref([]);
    const selectedItems = ref([]);
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });

    // 数据去重
    const goodsesDedupe = oldGoodses => {
      const ids = new Set();
      oldGoodses.forEach(goods => ids.add(goods.id));
      const newGoodses = [];
      Array.from(ids).forEach(id => newGoodses.push(oldGoodses.find(goods => goods.id === id)));
      return newGoodses;
    };

    // 加载数据
    const load = keyword => {
      props.remoteMethod && props.remoteMethod(keyword).then($goodses => goodses.value = goodsesDedupe([...selectedItems.value, ...props.defaultData, ...$goodses])).finally(() => loading.value = false);
    };

    // 判断是否有默认数据
    if (props.defaultData && props.defaultData.length > 0 && Object.keys(props.defaultData[0]).length > 0) {
      goodses.value = props.defaultData;
    }

    // 监听默认数据变化
    watch([() => props.defaultData], () => {
      if (props.defaultData && props.defaultData.length > 0 && Object.keys(props.defaultData[0]).length > 0) {
        goodses.value = goodsesDedupe([...props.defaultData, ...goodses.value]);
      }
    });
    return {
      inputRef,
      keyword,
      goodses,
      loading,
      selectedItems,
      model,
      load,
      handleRemoteMethod: keyword => {
        if (keyword) {
          loading.value = true;
          load(keyword);
        } else {
          goodses.value = [];
        }
      },
      handleChange: vals => {
        selectedItems.value = goodsesDedupe([...goodses.value.filter(goods => vals.includes(goods.id))]);
        emit('selectedItems', selectedItems.value);
      },
      handleVisibleChange: val => {
        val && inputRef.value.focus();
      }
    };
  }
});