import { ElForm, ElFormItem } from 'element-plus';
import { provide, inject, reactive } from 'vue';
import { wrap } from '@chinamo/element-plus-ext/hooks/use-utils.mjs';

ElForm.setup = wrap(ElForm.setup, function (setup, props, context) {
  const formItems = reactive({});
  const addFormItem = formItem => {
    if (formItem) {
      formItems[formItem.uid] = formItem;
    }
  };
  provide('addFormItem', addFormItem);

  const render = setup(props, context);
  return function (ctx, ...args) {
    this.formItems = Object.values(formItems);
    const vnode = render.call(this, ctx, ...args);
    return vnode;
  };
});

ElFormItem.setup = wrap(ElFormItem.setup, function (setup, props, context) {
  const addFormItem = inject('addFormItem', undefined);

  const render = setup(props, context);
  return function (ctx, ...args) {
    addFormItem && addFormItem(this.$);
    const vnode = render.call(this, ctx, ...args);
    return vnode;
  };
});
