import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _ctx.circle ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    type: "success",
    icon: "eli-download",
    loading: _ctx.loading,
    onClick: _ctx.handleDownload
  }, null, 8, ["loading", "onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "success",
    icon: "eli-download",
    loading: _ctx.loading,
    onClick: _ctx.handleDownload
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["loading", "onClick"]));
}