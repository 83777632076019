import { defineComponent, ref, computed, watch } from 'vue';
export default defineComponent({
  name: 'EeAreaSelect',
  emits: ['selectedItem'],
  props: {
    defaultData: {
      type: Array,
      default: []
    },
    remoteMethod: {
      type: Function
    }
  },
  setup(props, {
    emit
  }) {
    const loading = ref(false);
    const areas = ref([]);
    const load = keyword => {
      props.remoteMethod && props.remoteMethod(keyword).then($areas => areas.value = $areas || []).finally(() => loading.value = false);
    };
    if (props.defaultData && props.defaultData.length > 0 && Object.keys(props.defaultData[0]).length > 0) {
      areas.value = props.defaultData;
    }
    watch([() => props.defaultData], () => {
      if (props.defaultData && props.defaultData.length > 0 && Object.keys(props.defaultData[0]).length > 0) {
        areas.value = props.defaultData;
      }
    });
    return {
      areas,
      loading,
      handleRemoteMethod: keyword => {
        if (keyword) {
          loading.value = true;
          load(keyword);
        } else {
          areas.value = [];
        }
      },
      handleChange: val => {
        if (areas.value) {
          emit('selectedItem', areas.value.find(area => val == area.id));
        }
      }
    };
  }
});