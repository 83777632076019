import { ElTable } from 'element-plus';
import { wrap } from '@chinamo/element-plus-ext/hooks/use-utils.mjs';

// 粘性水平滚动条
ElTable.props.stickyHorizontalScroll = Boolean;
ElTable.render = wrap(ElTable.render, function (render, props, ...args) {
  const vnode = render.call(this, props, ...args);
  if (props.stickyHorizontalScroll) {
    vnode.props.class = `${vnode.props.class} el-table--sticky-horizontal-scroll`;
  }
  return vnode;
});
