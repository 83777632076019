import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import { defineComponent, ref, computed, watch } from 'vue';
import { ElMessage as $msg } from 'element-plus';
import word from '@/assets/image/word.png';
import pdf from '@/assets/image/pdf.png';
import excel from '@/assets/image/excel.png';
import zip from '@/assets/image/zip.png';
export default defineComponent({
  name: 'EeFileUploadDialog',
  emits: ['update:visible', 'uploadData', 'uploadSuccess'],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    actionData: {
      type: Object,
      default: {}
    },
    acceptFileTypes: {
      type: Array,
      default: []
    },
    limit: {
      type: Number
    }
  },
  setup(props, {
    emit
  }) {
    const uploadRef = ref();
    const data = ref();
    const uploading = ref(false);
    const uploadDisabled = ref(false);
    const extensions = {
      FILE: ['zip', 'rar', '7z', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'],
      IMAGE: ['jpg', 'jpeg', 'bmp', 'gif', 'png'],
      MEDIA: ['swf', 'flv', 'mp3', 'wav', 'avi', 'rm', 'rmvb']
    };
    let acceptExts = [];
    const selectedExts = ref([]);
    let uploadPercentage = ref(0);
    props.acceptFileTypes.forEach(type => {
      if (extensions[type]) {
        acceptExts.push(...extensions[type]);
      } else {
        acceptExts.push(type);
      }
    });
    const dialogVisible = computed({
      set(val) {
        emit('update:visible', val);
      },
      get() {
        return props.visible;
      }
    });
    watch(selectedExts.value, () => {
      uploadDisabled.value = false;
      if (props.acceptFileTypes.length > 0 && selectedExts.value.length > 0) {
        try {
          selectedExts.value.forEach(ext => {
            if (!acceptExts.includes(ext)) {
              throw new Error();
            }
          });
        } catch (error) {
          uploadDisabled.value = true;
        }
      }
    });
    const fileExt = filename => {
      if (filename.indexOf('.') != -1) {
        const names = filename.split('.');
        return names[names.length - 1].toLowerCase();
      }
      return '';
    };
    const clearFiles = () => {
      uploadRef.value.clearFiles();
      selectedExts.value = [];
    };
    return {
      acceptExts,
      uploadRef,
      dialogVisible,
      data,
      uploadDisabled,
      uploading,
      uploadPercentage,
      imgSrc(file) {
        if (file.raw.type.includes('image')) {
          return file.url;
        } else {
          const type = fileExt(file.name);
          switch (type) {
            case 'doc':
            case 'docx':
              return word;
            case 'xls':
            case 'xlsx':
              return excel;
            case 'zip':
            case 'rar':
              return zip;
            case 'pdf':
              return pdf;
          }
        }
      },
      handleRemove(file) {
        const ext = fileExt(file.name);
        if (selectedExts.value.includes(ext)) {
          selectedExts.value.splice(selectedExts.value.findIndex(sext => sext === ext), 1);
        }
        uploadRef.value.handleRemove(file);
      },
      handleExceed(files) {},
      fileChange: file => {
        if (file.status == 'ready') {
          selectedExts.value.push(fileExt(file.name));
        }
      },
      handleProgress(uploadProgressEvent) {
        uploadPercentage.value = parseInt(uploadProgressEvent.percent);
      },
      handleUpload() {
        if (selectedExts.value.length > 0) {
          emit('uploadData', $data => data.value = $data);
          uploading.value = true;
          uploadRef.value.submit();
        } else {
          $msg.error('请选择文件！');
        }
      },
      uploadSuccess: $result => {
        uploading.value = false;
        clearFiles();
        emit('uploadSuccess', $result, success => {
          if (success) {
            dialogVisible.value = false;
          }
        });
      },
      uploadError: error => {
        uploading.value = false;
        if (error && error.message) {
          const mes = JSON.parse(error.message);
          $msg.error(mes.message);
        } else {
          $msg.error('文件上传失败！');
        }
      },
      handleCloseDialog() {
        clearFiles();
        dialogVisible.value = false;
      }
    };
  }
});