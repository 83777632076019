import numeral from 'numeral';

/**
 *  数字格式化
 * @param {*} val 待格式化值
 * @param {*} pattern 格式，默认格式：0,0.00
 * @returns 格式化结果
 */
function numeralFormat(val, pattern) {
  if (!pattern) {
    pattern = '0,0.00';
  }
  return (val && numeral(val).format(pattern)) || '0.00';
}

/**
 * 数字反格式化
 * @param {*} val 数字字符串
 * @returns
 */
function numeralValue(val) {
  return (val && numeral(val).value()) || 0.0;
}

export default { numeral, numeralFormat, numeralValue };
