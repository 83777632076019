import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  class: "el-upload__tip single-image-uploader__tip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_eli_plus = _resolveComponent("eli-plus");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_upload = _resolveComponent("el-upload");
  return _openBlock(), _createBlock(_component_el_upload, {
    ref: "singleImageUploadRef",
    class: "single-image-uploader",
    action: `${_ctx.$http.defaults.baseURL}${_ctx.action}`,
    data: {
      fileType: 'IMAGE'
    },
    "show-file-list": false,
    "on-change": _ctx.handelChange
  }, _createSlots({
    default: _withCtx(() => [_ctx.imageUrl || _ctx.defaultImageUrl ? (_openBlock(), _createBlock(_component_el_image, {
      key: 0,
      style: {
        "width": "100%",
        "height": "100%"
      },
      src: _ctx.imageUrl || _ctx.defaultImageUrl,
      fit: "scale-down"
    }, null, 8, ["src"])) : (_openBlock(), _createBlock(_component_el_icon, {
      key: 1,
      class: "single-image-uploader-icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_eli_plus)]),
      _: 1
    }))]),
    _: 2
  }, [_ctx.tip ? {
    name: "tip",
    fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.tip), 1)]),
    key: "0"
  } : undefined]), 1032, ["action", "on-change"]);
}