import { defineComponent, ref, watch } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'EeDaterangePicker',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: Array,
      default: []
    }
  },
  setup(props, {
    emit
  }) {
    const startDate = ref('');
    const endDate = ref('');
    const rowClassName = ref('');
    const setDate = () => {
      startDate.value = '';
      endDate.value = '';
      if (props.modelValue) {
        if (props.modelValue[0]) {
          startDate.value = props.modelValue[0];
        }
        if (props.modelValue[1]) {
          endDate.value = props.modelValue[1];
        }
      }
    };
    setDate();
    watch([() => props.modelValue], (newValue, oldValue) => {
      if (newValue.toString() != oldValue.toString()) {
        setDate();
      }
    });
    const handleDatePickerChange = () => {
      emit('update:modelValue', [startDate.value, endDate.value]);
      emit('change', [startDate.value, endDate.value]);
    };
    return {
      startDate,
      endDate,
      rowClassName,
      handleDatePickerChange,
      startDisableDate: date => {
        if (endDate.value) {
          return date.getTime() > dayjs(endDate.value).toDate().getTime();
        }
        return false;
      },
      endDisableDate: date => {
        if (startDate.value) {
          return date.getTime() < dayjs(startDate.value).toDate().getTime();
        }
        return false;
      }
    };
  }
});