import { defineComponent, ref, onMounted, watch, computed } from 'vue';
export default defineComponent({
  name: 'EeTrainLineStationSelect',
  emits: ['update:modelValue'],
  props: {
    modelValue: Number | Array,
    defaultData: {
      type: Array,
      default: []
    },
    initMethod: {
      type: Function
    },
    initParams: {
      type: Object
    }
  },
  setup(props, {
    emit,
    expose
  }) {
    const stations = ref([]);
    const hasData = ref(false);
    const model = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    const init = () => {
      if (props.defaultData.length > 0) {
        stations.value = props.defaultData;
      } else {
        if (props.initParams.trainLineId) {
          props.initMethod && props.initMethod({
            ...props.initParams
          }).then($stations => {
            stations.value = $stations || [];
            hasData.value = stations.value.length > 0 ? true : false;
            if ($stations.length == 1) {
              emit('update:modelValue', $stations[0].station.id);
            }
          });
        }
      }
    };
    onMounted(init);
    watch(() => props.initParams.trainLineId, () => {
      init();
    });
    expose({
      hasData
    });
    return {
      stations,
      model
    };
  }
});