import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "remote-show-suffix": "",
    "remote-method": _ctx.handleRemoteMethod,
    loading: _ctx.loading,
    onChange: _ctx.handelChange,
    placeholder: "输入关键字搜索线路",
    long: ""
  }, _createSlots({
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, line => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: line.id,
        label: line.name,
        value: line.id,
        "value-key": line.id
      }, null, 8, ["label", "value", "value-key"]);
    }), 128))]),
    _: 2
  }, [_ctx.$slots.prefix ? {
    name: "prefix",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "prefix")]),
    key: "0"
  } : undefined]), 1032, ["remote-method", "loading", "onChange"]);
}