import { defineComponent, ref, computed } from 'vue';
import logo from '@/assets/image/logo.png';
export default defineComponent({
  name: 'EeFilePreview',
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    previews: {
      type: Array,
      default: [{
        name: '',
        type: '',
        url: ''
      }],
      required: true
    },
    active: {
      type: Number,
      default: 0
    }
  },
  setup(props, {
    emit
  }) {
    const imageSuffix = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];
    const currentFilename = ref('');
    const carouselRef = ref(null);
    const dialogVisible = computed({
      set(val) {
        emit('update:visible', val);
      },
      get() {
        return props.visible;
      }
    });
    const setCurrentPreview = index => {
      let currentPreview = props.previews[index];
      if (currentPreview) {
        currentFilename.value = currentPreview.name;
        let currentDom = document.getElementById(currentPreview.id + '');
        if (currentDom && currentDom.src != currentPreview.url) {
          currentDom.src = currentPreview.url;
        }
      }
    };
    return {
      imageSuffix,
      currentFilename,
      carouselRef,
      dialogVisible,
      handleCarouselChange: index => {
        setCurrentPreview(index);
      },
      handleOpenedDialog: () => {
        carouselRef.value.setActiveItem(props.active);
        setCurrentPreview(props.active);
      },
      handleCloseDialog: () => {
        dialogVisible.value = false;
      },
      logo
    };
  }
});