import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.model = $event),
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stations, station => {
      return _openBlock(), _createBlock(_component_el_option, {
        label: station.station.site,
        value: station.station.id,
        "value-key": station.station.id
      }, null, 8, ["label", "value", "value-key"]);
    }), 256))]),
    _: 1
  }, 8, ["modelValue"]);
}