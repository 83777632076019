import { reactive, ref } from 'vue';

export const version = ref();
export const apiKey = ref();
export const librarys = {};

window.__BAIDU_MAP_SCRIPT_LOADERS = window.__BAIDU_MAP_SCRIPT_LOADERS || {};
window.BMAP_PROTOCOL = 'https';

function getScript(name, src) {
  if (!window.__BAIDU_MAP_SCRIPT_LOADERS[name]) {
    window.__BAIDU_MAP_SCRIPT_LOADERS[name] = new Promise(resolve => {
      if (!src) {
        return resolve();
      }

      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      document.body.appendChild(script);
    });
  }

  return window.__BAIDU_MAP_SCRIPT_LOADERS[name];
}

function getApiScript() {
  const t = new Date().getTime();
  const src = `https://api.map.baidu.com/getscript?v=${version.value}&ak=${apiKey.value}&t=${t}`;
  return getScript('__API__', src);
}

function getLibScript(libname) {
  let src = librarys[libname];
  if (src && typeof src === 'function') {
    src = src();
  }
  return getScript(libname, src);
}

export const injectScript = (libnames = []) => {
  return getApiScript().then(() => Promise.all(libnames.map(libname => getLibScript(libname))));
};
