import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_main = _resolveComponent("el-main");
  const _directive_print = _resolveDirective("print");
  return _openBlock(), _createBlock(_component_el_main, {
    style: {
      "background-color": "#f0f2f5"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      justify: "center",
      class: "print-view"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(_ctx.portrait ? 'print-view-portrait' : 'print-view-transverse')
      }, [_createVNode(_component_el_row, {
        class: "print-view-button",
        padding: ""
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          icon: "eli-printer"
        }, {
          default: _withCtx(() => [_createTextVNode(" 打印 ")]),
          _: 1
        })), [[_directive_print, _ctx.printOption]]), _createVNode(_component_el_button, {
          type: "danger",
          link: ""
        }, {
          default: _withCtx(() => [_createTextVNode(" 打印布局使用【" + _toDisplayString(_ctx.portrait ? '纵向' : '横向') + "】打印，打印边距请选择“无” ", 1)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        padding: ""
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "tools")]),
        _: 3
      }), _createElementVNode("div", {
        class: "print-view-area",
        id: `${_ctx.printId}`
      }, [_renderSlot(_ctx.$slots, "default")], 8, _hoisted_1)], 2)]),
      _: 3
    })]),
    _: 3
  });
}