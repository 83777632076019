import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { defineComponent, ref } from 'vue';
import { ElMessage as $msg } from 'element-plus';
export default defineComponent({
  name: 'EeSingleImageUpload',
  props: {
    action: {
      type: String,
      required: true
    },
    defaultImageUrl: {
      type: String
    },
    tip: {
      type: String,
      default: null
    }
  },
  setup(props, {
    emit
  }) {
    const singleImageUploadRef = ref();
    const imageUrl = ref();
    return {
      singleImageUploadRef,
      imageUrl,
      handelChange: (file, fileList) => {
        const fileRaw = file.raw;
        if (fileRaw.type.indexOf('image') == -1) {
          $msg.error('只能上传图片！');
          singleImageUploadRef.value.clearFiles();
          return;
        } else if (fileRaw.size / 1024 / 1024 > 5) {
          $msg.error('图片不能大于5MB！');
          singleImageUploadRef.value.clearFiles();
          return;
        }
        imageUrl.value = URL.createObjectURL(file.raw);
      }
    };
  }
});