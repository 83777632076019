import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'EeTrainSelect',
  props: {
    defaultData: {
      type: Array,
      default: []
    },
    remoteMethod: {
      type: Function
    },
    remoteParams: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const loading = ref(false);
    const trains = ref([]);
    const load = keyword => {
      if (keyword) {
        props.remoteParams.keyword = keyword;
      }
      if (Object.keys(props.remoteParams).length > 0) {
        props.remoteMethod({
          ...props.remoteParams
        }).then($trains => trains.value = $trains || []).finally(() => loading.value = false);
      }
    };
    if (props.defaultData.length > 0) {
      trains.value = props.defaultData;
    } else {
      load(null);
    }
    return {
      trains,
      loading,
      handleRemoteMethod: keyword => {
        if (keyword) {
          loading.value = true;
          load(keyword);
        } else {
          trains.value = [];
        }
      }
    };
  }
});